<template>
    <div>
        <s-header :name="'食品溯源'"></s-header>
        <img style="width: 100%" src="@/assets/bg.png" />
        <div class="content">
            <div class="top">
                <span>{{info && info.product_name}} {{info && info.product_specs}}</span>
                <span class="iconfont icon-anquanyupinzhizhuisu" style="color: #085C92; font-size: 30px"></span>
            </div>
            <p class="info">商品信息</p>
            <div class="table-box">
                <van-cell-group border>
                    <van-cell title="产品名称" :value="info && info.product_name" />
                    <van-cell title="品牌" :value="info && info.product_brand" />
                    <van-cell title="销售区域" :value="info && info.sales_area" />
                    <van-cell title="产品类型" :value="info && info.product_type" />
                    <van-cell title="执行标准" :value="info && info.standards" />
                    <van-cell title="保质期" :value="info && info.period" />
                    <van-cell title="生产企业" :value="info && info.commpany" />
                    <van-cell title="生产企业地址" :value="info && info.adreess" />
                </van-cell-group>
            </div>
            <div class="list">
                <van-steps direction="vertical" :active="stepList.length">
                    <van-step v-for="(val, index) in stepList" :key="index">
                        <template #inactive-icon>
                            <span class="iconfont" :class="val.icon || 'icon-yanshou'"></span>
                        </template>
                        <template #active-icon>
                            <span class="iconfont icon-anquanyupinzhizhuisu"></span>
                        </template>
                        <p class="title">{{ val.title }}</p>
                        <div style="display: flex;justify-content: space-between">
                            <div>
                                <p class="tip" v-for="(node, index1) in val.result" :key="index1">{{ node }}</p>
                            </div>
                            <p>
                                <span v-for="(item, count) in val.image" :key="count" @click="showPopup(item)" style="margin-right: 10px">查看</span>
                            </p>
                        </div>
                    </van-step>
                </van-steps>
                <van-search @click="onClick" v-model="value" disabled placeholder="请选择生产日期" />
                <van-popup
                        :show="showPop"
                        position="bottom"
                        label="有效日期"
                        custom-style="height: 50%;"
                        @close="onCancel"
                >
                    <van-date-picker
                            @cancel="onCancel"
                            @confirm="onConfirm"
                            v-model="currentDate"
                            title="选择日期"
                            :min-date="minDate"
                            :max-date="maxDate"
                    />
                </van-popup>
            </div>
            <van-popup closeable v-model:show="show" :style="{ width: '80%' }">
                <img style="width: 100%;" :src="imgSrc" />
            </van-popup>
            <p class="info">产品介绍</p>
        </div>
    </div>
</template>

<script>
    import sHeader from '@/components/SimpleHeader'
    import { reactive, onMounted, toRefs, ref } from 'vue'
    import { getDetails, getList } from '@/service/trace'
    export default {
        name: "Trace",
        components: {
            sHeader
        },
        setup() {
            const currentDate = ref(['2021', '01', '01']);
            const showPop = ref(false);
            const value = ref('');
            const info = ref(null);
            const id = ref('');
            const show = ref(false);
            const imgSrc = ref('')
            const state = reactive({
                currentDate,
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(2025, 5, 1),
                stepList: [
                    {
                        title: '原料验收',
                        result: ['原料索证齐全', '原料验收合格'],
                        icon: 'icon-yanshou',
                        image: []
                    },
                    {
                        title: '生产投料',
                        result: ['根据订单生产'],
                        icon: 'icon-shengchanlingliao',
                        image: []
                    },
                    {
                        title: '成品检测',
                        result: ['出厂检测合格'],
                        icon: 'icon-jiance',
                        image: []
                    },
                    {
                        title: '入库',
                        result: ['入库检测合格', 'GB 19300'],
                        icon: 'icon-ruku',
                        image: []
                    },
                    {
                        title: '批次追溯',
                        result: [],
                        image: [],
                        icon: 'icon-anquanyupinzhizhuisu'
                    }
                ]
            })
            onMounted(async () => {
                var url = window.location.href;
                var dz_url = url.includes('?') ? url.split('?')[1].split('=')[1] : '1';
                id.value = dz_url;
                const { data } = await getDetails({product_id: dz_url})
                info.value = data;
            })
            function onClick() {
                showPop.value = true
            }
            function onCancel() {
                showPop.value = false
            }
            async function onConfirm(val) {
                showPop.value = false
                value.value = val.selectedValues.join('-')
                const { data } = await getList({product_id: id.value, date: val.selectedValues.join('')})
                state.stepList = data
            }
            function showPopup(item) {
                show.value = true;
                imgSrc.value = item;
            }
            return {
                onClick,
                showPop,
                onCancel,
                onConfirm,
                value,
                info,
                id,
                show,
                showPopup,
                imgSrc,
                ...toRefs(state),
            }
        }
    }
</script>

<style scoped lang="less">
    ::v-deep .van-step--finish .van-step__circle, ::v-deep .van-step--finish .van-step__line{
        background: #ccc;
    }
    .content{
        background: #F7F7F7;
        border-top: 1px solid #ccc;
        .top{
            display: flex;
            margin: 0 20px;
            font-size: 20px;
            font-weight: bold;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            color: #D45510;
        }
        .info{
            height: 40px;
            line-height: 40px;
            margin: 0 0 12px;
            background: #E05304;
            color: #fff;
            padding-left: 20px;
            font-size: 16px;
        }
        .table-box{
            margin-bottom: 12px;
            ::v-deep .van-cell__value{
                text-align: left;
            }
        }
        ::v-deep .van-steps{
            padding-left: 60px;
        }
        .list{
            margin-bottom: 30px;
            .title{
                padding: 6px 10px;
                font-size: 16px;
                border: 1px solid #ccc;
                border-radius: 20px;
                color: #333;
            }
            .tip{
                font-size: 14px;
                color: #999;
                margin-left: 20px;
            }
        }
    }
    .iconfont{
        color: #fff;
        display: inline-block;
        font-size: 16px;
        padding: 8px;
        background: #0FBDD1;
        border-radius: 50%;
    }
</style>
